<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div class="flex items-center mb-4">
            <h1 class="text-4xl font-extrabold text-gray-900 mr-2">Analiza Politicianului: {{ politician.name }}</h1>
            <svg class="w-6 h-6 text-blue-500" fill="currentColor" viewBox="0 0 20 20">
                <path d="..."/>
            </svg>
        </div>
        <p class="text-lg font-medium text-gray-600">Explorează sursele, sentimentele și mențiunile cheie...</p>

        <!-- Source Statistics and Sentiment Distribution -->
        <div class="flex max-md:flex-wrap mb-8">
            <div class="mb-8 bg-white shadow-lg rounded-lg p-2 mr-4 w-1/2 max-md:w-full h-[450px]">
                <EChartComponent
                    refValue="mostFrequentSources"
                    key="1"
                    v-if="charts.sourceStats.series.length"
                    :chart-options="charts.sourceStats.options"
                    :chart-series="charts.sourceStats.series"
                    type="bar"/>
                <LoadingSpinner v-else></LoadingSpinner>
            </div>
            <div class="mb-8 bg-white shadow-lg rounded-lg p-2 w-1/2 max-md:w-full h-[450px]">
                <EChartComponent
                    refValue="sentimentDistribution"
                    key="2"
                    type="pie"
                    v-if="charts.sentimentDistribution.series.length"
                    :chart-options="charts.sentimentDistribution.options"
                    :chart-series="charts.sentimentDistribution.series"
                />
                <LoadingSpinner v-else></LoadingSpinner>

            </div>
        </div>
        <div class="flex max-md:flex-wrap w-full gap-6 mb-8">
            <div class="mb-8 bg-white shadow-lg rounded-lg p-2 w-1/2 max-md:w-full h-[450px]">
                <EChartComponent
                    refValue="test"
                    key="10"
                    type="line"
                    v-if="charts.affirmationTrends.series.length"
                    :chart-options="charts.affirmationTrends.options"
                    :chart-series="charts.affirmationTrends.series"
                />
                <LoadingSpinner v-else></LoadingSpinner>

            </div>

            <div class="mb-8 bg-white shadow-lg rounded-lg p-2 w-1/2 max-md:w-full h-[450px]">
                <EChartComponent
                    refValue="test"
                    key="10"
                    type="line"
                    v-if="charts.topTopics.series.length"
                    :chart-options="charts.topTopics.options"
                    :chart-series="charts.topTopics.series"
                />
                <LoadingSpinner v-else></LoadingSpinner>

            </div>
        </div>
        <!-- Word Clouds -->
        <div class="flex max-md:flex-wrap w-full gap-6 mb-8">
            <div class="mb-8 bg-white shadow-lg rounded-lg p-6 mr-4 w-1/2 max-md:w-full h-[400px]">
                <h2 class="font-bold mb-4 text-center text-lg text-gray-700">Cele mai Comune Cuvinte</h2>
                <WordCloud v-if="charts.wordCloud.mostCommonWords.series.length"
                           :word-data="charts.wordCloud.mostCommonWords.series"></WordCloud>
                <LoadingSpinner v-else></LoadingSpinner>
            </div>
            <div class=" mb-8 bg-white shadow-lg rounded-lg p-6  w-1/2 max-md:w-full h-[400px]">
                <h2 class="font-bold mb-4 text-center text-lg text-gray-700">Cele mai Menționate Entități</h2>
                <WordCloud v-if="charts.wordCloud.mostMentionedEntities.series.length"
                           :word-data="charts.wordCloud.mostMentionedEntities.series"></WordCloud>
                <LoadingSpinner v-else></LoadingSpinner>
            </div>
        </div>

        <p class="text-3xl font-bold text-gray-900 mb-4">{{ totalQuotes }} afirmații</p>

        <!-- Filters Section -->
        <div>
            <div class="mb-6 bg-gray-50 rounded-2xl p-4">
                <button @click="toggleFilters" class="text-gray-500 hover:text-gray-700">
                    <span>{{ showFilters ? "Ascunde filtre" : "Arată filtre" }}</span>
                </button>

                <div v-show="showFilters" class="mt-4">
                    <div class="mb-6 relative">
                        <input
                            v-model="filters.search"
                            type="text"
                            placeholder="Caută afirmații..."
                            class="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div class="flex flex-wrap">
                        <div class="w-full sm:w-1/3 p-2">
                            <label for="sourceFilter" class="block text-sm font-medium text-gray-700">Surse</label>
                            <select
                                v-model="filters.source"
                                class="mt-1 block w-full rounded-md border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option value="">Toate sursele</option>
                                <option v-for="source in sources" :key="source.id" :value="source.id">
                                    {{ source.name }}
                                </option>
                            </select>
                        </div>

                        <div class="w-full sm:w-1/3 p-2">
                            <label for="sentimentFilter"
                                   class="block text-sm font-medium text-gray-700">Sentiment</label>
                            <select
                                v-model="filters.sentiment"
                                class="mt-1 block w-full rounded-md border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option value="">Toate sentimentele</option>
                                <option value="Positive">Pozitiv</option>
                                <option value="Neutral">Neutru</option>
                                <option value="Negative">Negativ</option>
                            </select>
                        </div>

                        <div class="w-full sm:w-1/3 p-2">
                            <label for="entityFilter" class="block text-sm font-medium text-gray-700">Entități</label>
                            <select
                                v-model="filters.entity"
                                class="mt-1 block w-full rounded-md border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option value="">Toate entitățile</option>
                                <option v-for="entity in entities" :key="entity" :value="entity">
                                    {{ entity }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <button @click="applyFilters"
                            class="mt-4 ml-2 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">
                        Filtreaza
                    </button>
                </div>
            </div>

            <div>
                <div v-if="quotesData.length === 0" class="text-center py-8">
                    <p class="text-gray-600 text-lg">No quotes found matching your search.</p>
                </div>

                <LoadingSpinner v-if="loading"></LoadingSpinner>

                <div v-if="!loading">
                    <div class="quote-grid">
                        <div
                            v-for="(quote, index) in quotesData"
                            :key="index"
                            class="quote-card !p-4 bg-white rounded-md shadow hover:shadow-lg"
                        >
                            <p class="text-base">{{ quote.text }}</p>
                            <a
                                v-if="quote.article"
                                :href="quote.article.url"
                                target="_blank"
                                class="text-blue-500 hover:underline text-sm mt-2 block"
                            >
                                Sursa: {{ quote.article.title }}
                            </a>

                            <QuoteDetails :analysis="quote.analysis" v-if="quote.analysis"></QuoteDetails>
                        </div>
                    </div>

                    <button @click="loadMoreQuotes"
                            class="mt-8 w-full bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600">
                        Încarcă mai multe afirmații
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import LoadingSpinner from "../Components/LoadingSpinner.vue";
import QuoteDetails from "../Components/QuoteDetails.vue";
import WordCloud from "../Components/WordCloud.vue";
import { defineAsyncComponent } from 'vue';

const EChartComponent = defineAsyncComponent(() =>
    import(/* webpackChunkName: "echart-component" */ '@/Components/EChartComponent.vue')
);

export default {
    components: {
        WordCloud,
        QuoteDetails,
        LoadingSpinner,
        EChartComponent,
    },
    props: ["politician", "totalQuotes", "sources", "entities", "initialQuotes"],
    data() {
        return {
            quotes: this.initialQuotes,
            quotesData: this.initialQuotes.data,
            showFilters: false,
            filters: {
                search: "",
                source: "",
                sentiment: "",
                entity: "",
                perPage: 24,
            },
            loading: false,
            charts: {
                topMentionsChart: {
                    options: {},
                    series: [],
                },
                topTopics: {
                    options: {},
                    series: [],
                },
                affirmationTrends: {
                    options: {},
                    series: [],
                },
                sourceStats: {
                    options: {},
                    series: [],
                },
                sentimentDistribution: {
                    options: {},
                    series: [],
                },
                wordCloud: {
                    mostCommonWords: {
                        options: {},
                        series: [],
                    },
                    mostMentionedEntities: {
                        options: {},
                        series: [],
                    },
                }
            },
        };
    },
    methods: {
        applyFilters() {
            this.fetchQuotes();
        },
        toggleFilters() {
            this.showFilters = !this.showFilters;
        },
        async fetchChartsData() {
            try {
                const [sourceStats, sentimentDistribution, mostCommonWords, mostMentionedEntities, affirmationTrends, topTopics, topCoOccurances] = await Promise.all([
                    axios.get(`/api/politicians/${this.politician.id}/source-stats`),
                    axios.get(`/api/politicians/${this.politician.id}/sentiment-distribution`),
                    axios.get(`/api/politicians/${this.politician.id}/most-common-words`),
                    axios.get(`/api/politicians/${this.politician.id}/mentioned-entities`),
                    axios.get(`/api/politicians/${this.politician.id}/affirmation-trends`),
                    axios.get(`/api/politicians/${this.politician.id}/top-topics`),
                    axios.get(`/api/politicians/${this.politician.id}/top-co-occurrences`),
                ]);

                this.initSourceStatsChart(sourceStats.data);
                this.initSentimentDistributionChartECharts(sentimentDistribution.data);
                this.initWordCloudChart(mostCommonWords.data);
                this.initMostMentionedEntitiesChart(mostMentionedEntities.data);
                this.initAffirmationTrendsChart(affirmationTrends.data);
                this.initTopTopicsChart(topTopics.data);
            } catch (error) {
                console.error("Failed to fetch charts data:", error);
            }
        },
        initTopMentionsChart(data) {

            // Sort data by count in descending order for better visualization
            const sortedData = [...data].sort((a, b) => b.count - a.count);

            // Prepare data for the chart
            const entities = sortedData.map((item) => item.entity);
            const counts = sortedData.map((item) => item.count);

            this.charts.topMentionsChart.series = [
                {
                    name: "Count",
                    type: "bar",
                    data: counts,
                    itemStyle: {
                        color: "#5470c6", // Bar color
                    },
                },
            ];
            this.charts.topMentionsChart.options = {
                title: {
                    text: "Entity Mentions",
                    left: "center",
                },
                tooltip: {
                    trigger: "axis",
                },
                xAxis: {
                    type: "category",
                    data: entities,
                    axisLabel: {
                        interval: 0,
                        rotate: 45, // Rotate labels for better readability
                    },
                },
                yAxis: {
                    type: "value",
                    name: "Mentions",
                }
            };
        },
        initTopTopicsChart(data) {
            const values = data.map((item) => item.count);
            const labels = data.map((item) => item.entity);

            this.charts.topTopics.series = [
                {
                    name: 'Mentiuni',
                    type: 'bar',
                    data: values,
                    itemStyle: {
                        color: '#5470C6'
                    }
                }
            ];

            this.charts.topTopics.options = {
                title: {
                    text: 'Top Entitați',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter: function (params) {
                        const entity = params[0].axisValue;
                        const count = params[0].data;
                        return `Topic: ${entity}<br/>Mentions: ${count}`;
                    }
                },
                xAxis: {
                    type: 'value',
                    name: 'Mențiuni',
                    nameLocation: 'middle', // Moves the label to the left
                    nameGap: 20, // Adjust the gap between the label and axis
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                yAxis: {
                    type: 'category',
                    data: labels,
                    axisLabel: {
                        formatter: function (value) {
                            return value.length > 20 ? value.slice(0, 20) + '...' : value; // Trim long labels
                        }
                    }
                },
                grid: {
                    left: '00%',
                    right: '5%',
                    bottom: '10%',
                    center: 0,
                    containLabel: true
                }
            }
        },
        initAffirmationTrendsChart(data) {
            // Sort data by date
            const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

            // Extract dates and counts
            const dates = sortedData.map((item) => item.date);
            const counts = sortedData.map((item) => item.count);

            this.charts.affirmationTrends.series = [
                {
                    name: "Afirmații",
                    type: "line",
                    data: counts,
                    symbol: "none", // Hide data point markers
                    sampling: "lttb", // Downsample large datasets
                    itemStyle: {
                        color: "rgb(255, 70, 131)", // Line color
                    },
                },
            ];

            this.charts.affirmationTrends.options = {
                title: {
                    text: "Tendințe Afirmații",
                    subtext: "Date actualizate",
                    left: "center",
                },
                tooltip: {
                    trigger: "axis",
                    position: (pt) => [pt[0], "10%"], // Dynamic tooltip positioning
                    formatter: (params) => {
                        const {axisValue, data} = params[0];
                        return `<strong>${axisValue}</strong><br>Afirmații: ${data}`;
                    },
                },
                // toolbox: {
                //     feature: {
                //         dataZoom: {
                //             yAxisIndex: "none",
                //         },
                //         restore: {}, // Reset zoom
                //         saveAsImage: {}, // Save as image
                //     },
                // },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: dates,
                },
                yAxis: {
                    type: "value",
                    boundaryGap: [0, "100%"],
                },
                dataZoom: [
                    {
                        type: "inside", // Scroll-based zoom
                        start: 0,
                        end: 100, // Initial zoom range
                    },
                    {
                        type: "slider", // Slider-based zoom
                        start: 0,
                        end: 100,
                    },
                ],
            };
        },
        initSourceStatsChart(data) {
            let labels = data.map((item) => item.name);

            this.charts.sourceStats.series = [
                {
                    name: 'Cele mai frecvente surse',
                    type: 'pie',
                    radius: ['30%', '70%'], // Increased the radius for a larger chart
                    center: ['50%', '50%'], // Centered in the middle of the container
                    roseType: 'radius', // Optional, for a Nightingale chart effect
                    data: data,
                    itemStyle: {
                        borderRadius: 5,
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    emphasis: {
                        label: {
                            show: true,
                        },
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                },
            ];

            this.charts.sourceStats.options = {
                title: {
                    text: 'Cele mai frecvente surse',
                    subtext: 'Date actualizate',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)',
                },
                legend: {
                    orient: 'horizontal',
                    bottom: '0%',
                    left: 'center',
                    data: labels,
                },
                // toolbox: {
                //     show: true,
                //     feature: {
                //         mark: {show: true},
                //         dataView: {show: true, readOnly: false},
                //         restore: {show: true},
                //         saveAsImage: {show: true},
                //     },
                // },
            };
        },
        initSentimentDistributionChartECharts(data) {
            const labels = data.map((item) => item.name);
            const values = data.map((item) => item.value);

            // Calculate total and percentages
            const total = values.reduce((sum, count) => sum + count, 0);
            const percentages = values.map(count => ((count / total) * 100).toFixed(1));

            // Translation and color mapping
            const sentimentMap = {
                'positive': {
                    label: 'Pozitiv',
                    color: '#22C55E',
                },
                'negative': {
                    label: 'Negativ',
                    color: '#EF4444',
                },
                'neutral': {
                    label: 'Neutru',
                    color: '#64748B',
                }
            };

            // Transform the original labels to Romanian
            const romanianLabels = labels.map(label =>
                sentimentMap[label.toLowerCase()]?.label || label
            );

            // Get colors based on original English labels
            const colors = labels.map(label =>
                sentimentMap[label.toLowerCase()]?.color || '#64748B'
            );
            this.charts.sentimentDistribution.series = [
                {
                    name: 'Distributie Sentiment',
                    type: 'pie',
                    radius: ['30%', '60%'],
                    center: ['50%', '50%'],
                    data: labels.map((label, index) => ({
                        value: values[index],
                        name: romanianLabels[index],
                        itemStyle: {color: colors[index]}
                    })),
                    label: {
                        formatter: '{b}: {d}%',
                        textStyle: {
                            fontSize: 12,
                            fontWeight: 'bold'
                        }
                    }
                }
            ];
            // ECharts configuration
            this.charts.sentimentDistribution.options = {
                title: {
                    text: 'Cele mai frecvente surse',
                    subtext: 'Date actualizate',
                    left: 'center',
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: '95%',
                    left: 'center',
                    data: romanianLabels,
                    textStyle: {
                        fontSize: 12
                    }
                }
            };

        },
        initWordCloudChart(data) {
            this.charts.wordCloud.mostCommonWords.series = data.map((item) => ({
                text: item.word,
                weight: item.count,
            }));
        },
        initMostMentionedEntitiesChart(data) {
            this.charts.wordCloud.mostMentionedEntities.series = data.map((item) => ({
                text: item.entity,
                weight: item.count,
            }));
        },
        loadMoreQuotes() {
            this.filters.perPage += 24;
            this.fetchQuotes();
        },
        async fetchQuotes() {
            this.loading = true;
            try {
                const response = await axios.get(`/api/politicians/${this.politician.id}/quotes`, {
                    params: this.filters,
                });

                this.quotesData = response.data.data;
            } catch (error) {
                console.error("Failed to fetch quotes:", error);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        this.fetchChartsData();
    },
};
</script>
